import { Plugins } from '@capacitor/core';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { actionSheetController, isPlatform } from "@ionic/vue";
import Setting from "@/rest/Setting";
import CookieHelper from '@/utils/CookieHelper';
import { Camera, CameraOptions } from "@ionic-native/camera";
import { AndroidPermissions } from '@ionic-native/android-permissions';

/**
 * 文件上传工具类
 */
export class FileAttendanceHelper {

    constructor() {
    }

    private readonly fileSystemHead = 'file:';

    //相机参数
    private cameraOptions: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.CAMERA,
        destinationType: Camera.DestinationType.FILE_URI,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.FRONT,
    }

    private cameraOptionsBase64: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.CAMERA,
        destinationType: Camera.DestinationType.DATA_URL,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.FRONT,
    }

    // 从相册选取
    private photoOptions: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: Camera.DestinationType.FILE_URI,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.BACK,
    }

    //权限判断
    private checkPermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (isPlatform('android')) {
                let ps = [AndroidPermissions.PERMISSION.CAMERA, AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE];
                this.getNotHasPermissions(ps, 0).then((r: Array<string>) => {
                    if (r != null && r.length > 0) {
                        AndroidPermissions.requestPermissions(r).then((rr: any) => {
                            resolve(true);
                        }, (re: any) => {
                            console.log('申请权限失败：' + JSON.stringify(re));
                            reject(false);
                        });
                    } else {
                        resolve(true);
                    }
                }, (e: string) => {
                    console.log(e);
                    reject(false);
                });
            } else {
                resolve(true);
            }
        });
    }

    private getNotHasPermissions(ps: Array<string>, index: number): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            AndroidPermissions.checkPermission(ps[index]).then(
                (result: any) => {
                    ps.splice(index, 1);
                    if (ps.length > index) {
                        this.getNotHasPermissions(ps, index).then((r: Array<string>) => {
                            resolve(r);
                        }, (e: string) => {
                            reject(e);
                        });
                    } else {
                        resolve(ps);
                    }
                },
                (err: any) => {
                    if (ps.length > index + 1) {
                        this.getNotHasPermissions(ps, index + 1).then((r: Array<string>) => {
                            resolve(r);
                        }, (e: string) => {
                            reject(e);
                        });
                    } else {
                        resolve(ps);
                    }
                }
            ).catch((c: any) => {
                reject('权限检查异常');
            });
        });
    }

    //选择相机
    public openCamera(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.cameraOptions).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    public openCameraBase64(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.cameraOptionsBase64).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    //选择本地图片
    public openPhotoFolder(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.photoOptions).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log('图片选取异常：' + JSON.stringify(e));
                        reject("");
                    });
                }
            });
        });
    }

    private static actionSheet: any = null;

    /**
     * 文件上传弹出框-（拍照和相册）
     * @param successCallback 
     * @param errorCallback 
     */
    public async openFileSheet(successCallback: any, errorCallback: any) {
        FileAttendanceHelper.actionSheet = await actionSheetController.create({
            mode: 'ios',
            buttons: [
                {
                    text: "拍照",
                    handler: () => {
                        this.openCamera().then((result: any) => {
                            successCallback(result);
                        }, (e: string) => {
                            errorCallback("拍照失败");
                        });
                    },
                },
                {
                    text: "手机相册",
                    handler: () => {
                        this.openPhotoFolder().then((result: any) => {
                            successCallback(result);
                        }, (e: string) => {
                            errorCallback("选择图片失败");
                        });
                    },
                },
                {
                    text: "取消",
                    role: "cancel",
                },
            ],
        });
        return FileAttendanceHelper.actionSheet.present();
    }

    //base64转Blob
    public dataURLtoBlob(dataurl: any): Blob {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    private readonly fileTransfer: FileTransferObject = FileTransfer.create();

    //文件参数
    private static fileOptions: FileUploadOptions = {
        fileKey: 'file',
        fileName: '',
        httpMethod: "POST",
        mimeType: "text/plain",
        chunkedMode: false,
        headers: {},
        params: {}
    }

    /**
     * 本地文件上传
     * @param filePath //本地文件地址android或ios
     */
    public uploadFile(filePath: string, uploadPath: string, id?: string): Promise<any> {
        filePath = filePath.indexOf(this.fileSystemHead) == 0 ? filePath : (this.fileSystemHead + '//' + filePath);
        console.log('选择文件路径：' + filePath);
        const token = CookieHelper.get(Setting.authCookieName);
        if (token != null && token != "") {
            const header = {
                'X-APP-ID': Setting.defalutAPPID,
                'Authorization': JSON.parse(token).access_token
            }
            FileAttendanceHelper.fileOptions.headers = header;
        }
        if (id) {
            FileAttendanceHelper.fileOptions.params = { id: id };
        }
        //上传api地址
        let uploadUrl = encodeURI(uploadPath);
        return new Promise((resolve, reject) => {
            this.fileTransfer.upload(filePath, uploadUrl, FileAttendanceHelper.fileOptions)
                .then((data: any) => {
                    if (data && data.responseCode == 200) {
                        const result = JSON.parse(data.response);
                        resolve(result);
                    } else {
                        reject("上传失败");
                    }
                }, (err: any) => {
                    reject("上传异常");
                }).catch((c: any) => {
                    reject("上传异常");
                });
        });
    }
}

export interface Picture {
    url: string,
    status: string,
    message: string,
}

export enum UploadStatus {
    Uploading = 'uploading',
    Failed = 'failed',
    Done = 'done',
}

export enum UploadMessage {
    Uploading = '上传中...',
    Failed = '上传失败...',
    Done = '上传完成...',
}
